import React from "react";
import { Link } from "react-scroll";

class InteractiveLandingText extends React.Component {
	constructor(props) {
		super(props);
		this.handleViewJSONClick = this.handleViewJSONClick.bind(this);
	}

	handleViewJSONClick() {
		this.props.handleViewJSONClick();
	}

	render() {
		return (
			<div>
				<div className='text-6xl md:text-7xl text-forest-green font-bold pb-4'>
					a modern JSON viewer.
				</div>
				<div className='text-3xl text-gray-900 dark:text-off-white'>
					paste any JSON to vizualize your data and optimize your
					endpoints.
				</div>
				<Link to='jsonDisplay' smooth={true}>
					<button
						onClick={this.handleViewJSONClick}
						className='disabled:opacity-50 disabled:pointer-events-none mt-6 bg-fire-truck-red hover:bg-fire-truck-red-darker hover:shadow-lg text-white font-bold py-2 px-4 rounded inline-flex items-center'
						disabled={!this.props.editorContainsValidJson}>
						View
					</button>
				</Link>
			</div>
		);
	}
}

export default InteractiveLandingText;
