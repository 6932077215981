import { Link } from "react-scroll";
import React from "react";
import ThemeSwitch from "../theme-switch/theme-switch";

class Navigation extends React.Component {
	render() {
		return (
			<div
				id='navigation'
				className='flex items-center w-full px-8 sticky top-14 z-10 h-0'>
				<Link className='cursor-pointer' to='landing' smooth={true}>
					<div className='text-3xl text-gray-900 dark:text-off-white font-bold pointer-events-none flex items-center'>
						<img
							className='shadow-lg w-10 mr-2'
							alt='erwin-logo'
							src={process.env.PUBLIC_URL + "/logo.png"}
						/>
						erwin
					</div>
				</Link>
				<div className='ml-auto dark:animate-wiggle-slow animate-spin-slow'>
					<ThemeSwitch
						darkMode={this.props.darkMode}
						handleThemeChange={
							this.props.handleThemeChange
						}></ThemeSwitch>
				</div>
			</div>
		);
	}
}

export default Navigation;
