import classNames from "classnames";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const BooleanObject = (props) => {
	const [copied, setCopied] = useState(false);

	const handleChange = (event) => {
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 300);
	};

	return (
		<div className={classNames("py-1 flex")}>
			<span className='pr-2 flex items-center justify-center'>
				<svg
					className='w-6 h-6 text-yellow-500'
					fill='none'
					stroke='currentColor'
					viewBox='0 0 24 24'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth='2'
						d='M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z'></path>
				</svg>
			</span>
			<span className='pr-2 pointer-events-none flex items-center justify-center'>
				{props.objKey}:
			</span>
			<CopyToClipboard
				className='cursor-pointer'
				text={props.obj}
				onCopy={handleChange}>
				<span>{props.obj.toString()}</span>
			</CopyToClipboard>
			<span className={classNames("pl-2", { hidden: !copied })}>
				copied
			</span>
		</div>
	);
};
