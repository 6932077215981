import classNames from "classnames";

export const Credits = (props) => {
	return (
		<div
			className={classNames(
				"absolute bottom-0 pb-5 italics dark:text-off-white pl-0"
			)}>
			<div>
				Built by{" "}
				<a
					className='text-indigo-500 cursor-pointer'
					target='_blank'
					rel='noreferrer'
					href='https://www.linkedin.com/in/kevinkkngo/'>
					Kevin Ngo
				</a>
			</div>
			<small className='text-small dark:text-off-white'>
				light-switch animation by{" "}
				<a
					className='text-pink-600 cursor-pointer'
					rel='noreferrer'
					target='_blank'
					href='https://twitter.com/aaroniker_me'>
					Aaron Iker
				</a>
			</small>
		</div>
	);
};
