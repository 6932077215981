import classNames from "classnames";
import { BooleanObject } from "./boolean-object";
// import objectHash from "object-hash";
import { NullObject } from "./null-object";
import { NumberObject } from "./number-object";
import { StringObject } from "./string-object";
import { React, useState } from "react";
// import moment from "moment";

const DisplayedObject = (props) => {
	const [open, setOpen] = useState(props.open);
	const [isObj] = useState(
		!(
			!props.obj ||
			props.obj.length === 0 ||
			typeof props.obj !== "object"
		) || Object.prototype.toString.call(props.obj) === "[object Array]"
	);
	const [isArrayItem] = useState(props.isArrayItem);

	const getIconForObjectType = () => {
		let icon = null;
		if (!open) {
			icon = (
				<svg
					className='w-6 h-6'
					fill='none'
					stroke='currentColor'
					viewBox='0 0 24 24'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth='2'
						d='M9 5l7 7-7 7'></path>
				</svg>
			);
		} else {
			icon = (
				<svg
					className='w-6 h-6'
					fill='none'
					stroke='currentColor'
					viewBox='0 0 24 24'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth='2'
						d='M19 9l-7 7-7-7'></path>
				</svg>
			);
		}

		return icon;
	};

	const getDisplayedValueForObjectType = () => {
		let value = null;
		switch (typeof props.obj) {
			case "boolean":
				value = (
					<BooleanObject
						obj={props.obj}
						objKey={props.objKey}></BooleanObject>
				);
				break;

			case "number":
				value = (
					<NumberObject
						obj={props.obj}
						objKey={props.objKey}></NumberObject>
				);
				break;

			case "string":
				value = (
					<StringObject
						obj={props.obj}
						objKey={props.objKey}></StringObject>
				);
				break;

			case "object":
			default:
				value = <span className='font-extrabold'>{props.objKey}</span>;
				break;
		}

		if (props.objKey === "") {
			value = <span>{"(" + typeof props.obj + ")"}</span>;
		}
		// parent is array
		else if (isArrayItem) {
			value = <span>[{props.objKey}]</span>;
		}
		// empty array
		else if (isObj && props.obj.length === 0) {
			value = <span className='font-bold'>{props.objKey}</span>;
		}
		return value;
	};

	const handleClick = () => {
		setOpen(!open);
	};

	if (!isObj) {
		return (
			<>
				{props.obj !== null && getDisplayedValueForObjectType()}
				{props.obj === null && (
					<NullObject
						obj={props.obj}
						objKey={props.objKey}></NullObject>
				)}
			</>
		);
	} else {
		return (
			<div className='foldercontainer py-1'>
				<button
					onClick={handleClick}
					className={classNames("cursor-pointer flex items-center")}>
					<span className='pr-2'>{getIconForObjectType()}</span>
					{Object.prototype.toString.call(props.obj) ===
						"[object Array]" && (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className='h-6 w-6 mr-2 text-yellow-700'
							viewBox='0 0 20 20'
							fill='currentColor'>
							<path d='M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z' />
						</svg>
					)}
					{getDisplayedValueForObjectType()}:{" "}
					{isObj && props.obj.length === 0 && (
						<span className='pl-2'>[]</span>
					)}
				</button>
				<div
					className={classNames(
						"pl-8 overflow-scroll md:overflow-hidden transition-max-height max-h-100 duration-500 ease-in-out",
						{ "max-h-0": !open }
					)}>
					{Object.keys(props.obj).map((currentKey, i) => {
						let uniqueKey =
							props.objKey.toString() + "_" + currentKey;

						return (
							<DisplayedObject
								obj={props.obj[currentKey]}
								key={uniqueKey}
								objKey={currentKey.toString()}
								isArrayItem={
									Object.prototype.toString.call(
										props.obj
									) === "[object Array]"
								}
								open={open}
							/>
						);
					})}
				</div>
			</div>
		);
	}
};

export default DisplayedObject;
