import classNames from "classnames";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const NumberObject = (props) => {
	const [copied, setCopied] = useState(false);

	const handleChange = (event) => {
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 300);
	};

	const getIconForObjectType = () => {
		if (props.objKey.toUpperCase() === "ID") {
			return (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					className='h-6 w-6 text-yellow-300'
					viewBox='0 0 20 20'
					fill='currentColor'>
					<path
						fillRule='evenodd'
						d='M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z'
						clipRule='evenodd'
					/>
				</svg>
			);
		} else {
			return (
				<svg
					className='w-6 h-6 text-forest-green'
					fill='none'
					stroke='currentColor'
					viewBox='0 0 24 24'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth='2'
						d='M7 20l4-16m2 16l4-16M6 9h14M4 15h14'></path>
				</svg>
			);
		}
	};

	const getDisplayedValueForObjectType = () => {
		return (
			<span className={classNames({ "text-red-500": props.obj < 0 })}>
				{props.obj.toString()}
			</span>
		);
	};

	return (
		<div className={classNames("py-1 flex")}>
			<span className='pr-2 flex items-center justify-center'>
				{getIconForObjectType()}
			</span>
			<span className='pr-2 pointer-events-none'>{props.objKey}:</span>
			<CopyToClipboard
				className='cursor-pointer'
				text={props.obj}
				onCopy={handleChange}>
				{getDisplayedValueForObjectType()}
			</CopyToClipboard>
			<span className={classNames("pl-2", { hidden: !copied })}>
				copied
			</span>
		</div>
	);
};
