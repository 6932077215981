import classNames from "classnames";
import moment from "moment";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const StringObject = (props) => {
	const [copied, setCopied] = useState(false);

	const handleChange = (event) => {
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 300);
	};

	const getIconForObjectType = () => {
		if (props.objKey.toUpperCase() === "ID") {
			return (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					className='h-6 w-6 text-yellow-300'
					viewBox='0 0 20 20'
					fill='currentColor'>
					<path
						fillRule='evenodd'
						d='M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z'
						clipRule='evenodd'
					/>
				</svg>
			);
		} else if (props.objKey.toUpperCase() === "COUNTRY") {
			return (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					className='h-6 w-6 text-gray-400'
					viewBox='0 0 20 20'
					fill='currentColor'>
					<path
						fillRule='evenodd'
						d='M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z'
						clipRule='evenodd'
					/>
				</svg>
			);
		} else if (props.objKey.toUpperCase() === "LOCATION") {
			return (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					className='h-6 w-6 text-gray-400'
					viewBox='0 0 20 20'
					fill='currentColor'>
					<path
						fillRule='evenodd'
						d='M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z'
						clipRule='evenodd'
					/>
				</svg>
			);
		} else if (moment(props.obj, moment.ISO_8601).isValid()) {
			return (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					className='h-6 w-6 text-pink-600'
					viewBox='0 0 20 20'
					fill='currentColor'>
					<path
						fillRule='evenodd'
						d='M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z'
						clipRule='evenodd'
					/>
				</svg>
			);
		} else if (props.obj.match(/\.(jpeg|jpg|gif|png)$/) != null) {
			return (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					className='h-6 w-6 text-red-300'
					viewBox='0 0 20 20'
					fill='currentColor'>
					<path
						fillRule='evenodd'
						d='M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z'
						clipRule='evenodd'
					/>
				</svg>
			);
		} else {
			return (
				<svg
					className='w-6 h-6 text-indigo-500'
					fill='none'
					stroke='currentColor'
					viewBox='0 0 24 24'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth='2'
						d='M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z'></path>
				</svg>
			);
		}
	};

	const getDisplayedValueForObjectType = () => {
		if (moment(props.obj, moment.ISO_8601).isValid()) {
			let momentDateTime = moment(props.obj, moment.ISO_8601).format(
				"MMMM Do YYYY, h:mm:ss a"
			);
			return (
				<span className='cursor-pointer'>
					<span>{props.obj.toString()}</span>
					<span className='pl-2 text-pink-600'>
						({momentDateTime})
					</span>
				</span>
			);
		} else {
			if (props.obj.toString().length > 75) {
				return <span>{props.obj.toString().substring(0, 75)} ...</span>;
			} else {
				return <span>{props.obj.toString()}</span>;
			}
		}
	};

	return (
		<div className={classNames("py-1 flex")}>
			<span className='pr-2 flex items-center justify-center'>
				{getIconForObjectType()}
			</span>
			<span className='pr-2 pointer-events-none flex items-center justify-center'>
				{props.objKey}:
			</span>
			<CopyToClipboard
				className='cursor-pointer'
				text={props.obj}
				onCopy={handleChange}>
				{getDisplayedValueForObjectType()}
			</CopyToClipboard>
			<span
				className={classNames("pl-2 flex align-items-center", {
					hidden: !copied,
				})}>
				<span>copied</span>
			</span>
		</div>
	);
};
