import React from "react";

import "./theme-switch.scss";

class ThemeSwitch extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.handleThemeChange();
    }

    render() {
        return (
            <label className="dayNight">
                <input
                    type="checkbox"
                    checked={!this.props.darkMode}
                    onChange={this.handleClick}
                />
                <div></div>
            </label>
        );
    }
}

export default ThemeSwitch;
