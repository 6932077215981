import { BackToTop } from "./components/back-to-top/back-to-top";
import { Credits } from "./components/credits/credits";
import InteractiveDisplayedJson from "./components/interactive-displayed-json/interactive-displayed-json";
import InteractiveLandingText from "./components/interactive-landing-text/interactive-landing-text";
import Navigation from "./components/navigation/navigation";
import React from "react";
import { Element } from "react-scroll";
import StyledEditor from "./components/editor/editor";

class App extends React.Component {
	constructor(props) {
		super(props);
		this.initializeEditor = this.initializeEditor.bind(this);
		this.handleThemeChange = this.handleThemeChange.bind(this);
		this.handleViewJSONClick = this.handleViewJSONClick.bind(this);
		this.handleEditorChange = this.handleEditorChange.bind(this);
		this.state = {
			editorContainsValidJson: true,
			editorText: "",
			jsonDisplayed: false,
			darkMode: false,
		};
		this.editor = null;
	}

	initializeEditor(editor) {
		this.editor = editor;
		this.setState({
			editorContainsValidJson: true,
			editorText: editor.getValue(),
		});
	}

	handleEditorChange(value) {
		this.setState({ editorContainsValidJson: false });
		let valid = true;
		let editorText = null;

		if (value === undefined || value === null) {
			valid = false;
		} else {
			editorText = value;
			valid = true;
		}

		if (editorText != null) {
			this.setState((state, props) => ({
				editorContainsValidJson: valid,
				editorText: this.editor.getValue(),
			}));
		}
	}

	handleViewJSONClick() {
		if (this.editor != null) {
			this.editor.getAction("editor.action.formatDocument").run();

			this.setState({
				jsonDisplayed: true,
				editorText: this.editor.getValue(),
			});
		}
	}

	handleThemeChange() {
		this.setState((state, props) => ({
			darkMode: !state.darkMode,
		}));
	}

	componentDidUpdate() {
		if (this.state.darkMode) {
			document.body.classList.add("dark");
		} else {
			document.body.classList.remove("dark");
		}
	}

	render() {
		return (
			<div className='App container mx-auto flex flex-col relative'>
				<Navigation
					darkMode={this.state.darkMode}
					handleThemeChange={this.handleThemeChange}
				/>
				<Element
					id='landing'
					name='landing'
					className='h-screen relative'>
					<div className='grid grid-cols-12 h-full'>
						<div className='col-span-12 pt-12 md:pt-0 md:col-span-4 flex items-center px-8'>
							<InteractiveLandingText
								editorContainsValidJson={
									this.state.editorContainsValidJson
								}
								handleViewJSONClick={this.handleViewJSONClick}
							/>
						</div>
						<div className='col-span-12 md:col-span-8 flex md:items-center px-8'>
							<StyledEditor
								initializeEditor={this.initializeEditor}
								handleEditorChange={this.handleEditorChange}
								darkMode={this.state.darkMode}></StyledEditor>
						</div>
					</div>

					<span className='absolute bottom-0  w-3/4 md:w-1/4 pl-8'>
						<Credits
							jsonDisplayed={this.state.jsonDisplayed}></Credits>
					</span>
				</Element>

				<Element name='jsonDisplay' className='flex justify-center'>
					{this.state.jsonDisplayed && (
						<div className='h-screen w-80 md:w-full md:px-8 relative flex justify-center pt-10 items-center'>
							<InteractiveDisplayedJson
								editorText={this.state.editorText}
							/>

							<BackToTop />
						</div>
					)}
				</Element>
			</div>
		);
	}
}

export default App;
