import DisplayedObject from "../displayed-objects/displayed-object";
import React from "react";

class InteractiveDisplayedJson extends React.Component {
	constructor(props) {
		super(props);
		let jsonObj = this.getJsonObject(props);
		this.state = {
			OriginalJSONObject: jsonObj,
		};
	}

	getJsonObject(currentProps) {
		let jsonObj = null;
		try {
			jsonObj = JSON.parse(currentProps.editorText);
		} catch (error) {}
		return jsonObj;
	}

	componentWillReceiveProps(nextProps) {
		let jsonObj = this.getJsonObject(nextProps);
		if (jsonObj != null) {
			this.setState({
				OriginalJSONObject: jsonObj,
			});
		}
	}

	render() {
		return (
			<>
				<div className='relative scrollbar-thin dark:scrollbar-thumb-off-white scrollbar-thumb-text-gray-900 scrollbar-track-transparent w-full overflow-auto max-h-3/4 min-h-3/4 border-2 border-gray-300 rounded-xl p-10 text-gray-900 dark:text-off-white shadow-lg text-xl transition-height'>
					<DisplayedObject
						key={"master-obj"}
						objKey={"object"}
						obj={this.state.OriginalJSONObject}
						open={false}
					/>
				</div>
			</>
		);
	}
}

export default InteractiveDisplayedJson;
