import Editor from "@monaco-editor/react";
import moment from "moment";
import React from "react";

import "./editor.css";

class StyledEditor extends React.Component {
	constructor(props) {
		super(props);
		this.handleEditorDidMount = this.handleEditorDidMount.bind(this);
		this.handleEditorChange = this.handleEditorChange.bind(this);
		this.state = {
			// eslint-disable-next-line no-useless-escape
			code: `{\r\n\t\"id\": 13,\r\n\t\"age\": null,\r\n\t\"photo_id\": \"https:\/\/res.cloudinary.com\/erwin-json\/image\/upload\/v1631740732\/erwin-smith_dmbc1f.png\",\r\n\t\"branch\": \"scouts\",\r\n\t\"height_cm\": 188,\r\n\t\"name\": {\r\n\t\t\"first\": \"Erwin\",\r\n\t\t\"last\": \"Smith\"\r\n\t},\r\n\t\"expeditions\": [\r\n\t\t{\r\n\t\t\t\"name\": \"Find Land\",\r\n\t\t\t\"time\": \"${moment().toISOString()}\"\r\n\t\t}\r\n\t]\r\n}\r\n`,
		};
	}

	handleEditorChange(value, event) {
		// here is the current value
		this.props.handleEditorChange(value);
	}

	handleEditorDidMount(editor, monaco) {
		this.props.initializeEditor(editor);
		editor.focus();
	}

	handleEditorWillMount(monaco) {}

	handleEditorValidation(markers) {}

	render() {
		const code = this.state.code;
		const options = {
			value: code,
			language: "json",
			fontFamily: "Vulf Sans",
			fontSize: "20px",
			theme: this.props.darkMode ? "vs-dark" : "vs-light",
			padding: {
				top: "10px",
			},
			tabSize: 8,
		};

		return (
			<Editor
				style={{ "background-color": "#1e1e1e" }}
				wrapperClassName='rounded-xl shadow-lg border-2 dark:border-gray-900 bg-white dark:bg-gray-900'
				height='60%'
				defaultLanguage='json'
				options={options}
				defaultValue={code}
				beforeMount={this.handleEditorWillMount}
				onChange={this.handleEditorChange}
				onMount={this.handleEditorDidMount}
				onValidate={this.handleEditorValidation}></Editor>
		);
	}
}

export default StyledEditor;
