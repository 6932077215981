import classNames from "classnames";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const NullObject = (props) => {
	const [copied, setCopied] = useState(false);

	const handleChange = (event) => {
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 300);
	};

	return (
		<div className={classNames("py-1 flex")}>
			<span className='pr-2 flex items-center justify-center'>
				<svg
					className='w-6 h-6 text-red-500'
					fill='none'
					stroke='currentColor'
					viewBox='0 0 24 24'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth='2'
						d='M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636'></path>
				</svg>
			</span>
			<span className='pr-2 pointer-events-none'>{props.objKey}:</span>
			<CopyToClipboard
				className='cursor-pointer'
				text={props.obj}
				onCopy={handleChange}>
				<span>null</span>
			</CopyToClipboard>
			<span className={classNames("pl-2", { hidden: !copied })}>
				copied
			</span>
		</div>
	);
};
