import { Link } from "react-scroll";

export const BackToTop = () => {
	return (
		<Link to='landing' smooth={true}>
			<span className='absolute animate-bounce hover:bg-blue-600 bg-blue-500 hover:shadow-lg bottom-0 right-0 mb-5 w-5 h-5 p-4 md:mr-8 cursor-pointer rounded items-center justify-center flex text-white'>
				<span>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						className='h-5 w-5 block'
						viewBox='0 0 20 20'
						fill='currentColor'>
						<path
							fillRule='evenodd'
							d='M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z'
							clipRule='evenodd'
						/>
					</svg>
				</span>
			</span>
		</Link>
	);
};
